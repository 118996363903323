.page-break-inside-avoid {
    page-break-inside: avoid;
  }
  
  @media print {
    .page-break-before {
      page-break-before: always;
    }
    .page-break-after {
      page-break-after: always;
    }
    .page-break-inside-avoid {
      page-break-inside: avoid;
    }
  }
  